<!--
 * @Author: Axs <i@axs.email>
 * @Date: 2021-01-22 15:38:05
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2022-05-25 10:55:57
 * @Description: 支付方式选择
 * @FilePath: /src/views/kfc/order/pay.vue
-->
<template>
    <div class="page">
        <div class="header">
            <div class="store-name"
                 v-if="orderDetail">
                {{orderDetail.kfcPlaceOrder.storeName}} {{orderDetail.eatTypeDesc}}订单
            </div>
            <div class="store-address"
                 v-if="orderDetail">
                {{orderDetail.kfcPlaceOrder.storeAddress}}
            </div>
            <div class="price"
                 v-if="orderDetail">{{orderDetail.totalPrice}}</div>
            <div class="time-down">支付剩余时间：{{downTimeText}}</div>
        </div>
        <div class="pay-list"
             v-if="payList.length > 0">
            <div class="pay-item"
                 @click="clickPay(index)"
                 v-for="(item, index) in payList"
                 :key="index">
                <img class="icon"
                     :src="item.icon">
                <div class="name">{{item.name}}</div>
                <img class="select"
                     :src="selectIndex == index?require('@/components/payType/icon-qsk-check-on.png') :require('@/components/payType/icon-check-not.png')">
            </div>
        </div>
        <div class="footer iphone-x-adaptation">
            <div class="pay-btn"
                 @click="submit">
                立即支付
            </div>
        </div>
    </div>
</template>
<script>
    import { GET_KFC_ORDER_INFO_OF_ORDER_NO, PLATFORM_ORDER_OVER, WX_PAY_JSAPI } from "api";
    import { QZ_PAY_TYPE, QZ_CURRENT_SCENE, QZ_PAY_ORDER_NO } from 'constant';
    import { PAGE_ORDER_INFO } from 'page';
    import pay from 'pay';
    let PAGE_GUID = '';

    export default {
        data() {
            return {
                orderNo: '',
                orderType: '',
                orderDetail: null,
                downTimeText: '--:--',
                downTime: 0,
                downTimer: null,
                payList: [],
                selectIndex: 0,
                jumpNum: 0,
            }
        },
        created() {
            this.jumpNum = 0;
            PAGE_GUID = this.$util.guid();
            this.init();
        },
        methods: {
            init() {
                this.orderNo = this.$route.query.orderNo;
                this.orderType = this.$route.query.orderType;
                this.getData();
                this.$util.setCurrentScene().then(res => {
                    if (res.success) {
                        let currentScene = res.currentScene;
                        if ((this.isSafari() || this.isEdge()) && this.$store.getters.isApp) {
                            this.otherPageJump();
                        }
                        if (this.$store.getters.isApp) {
                            // 普通app && 其他浏览器
                            this.payList = [{
                                name: '支付宝支付',
                                icon: require('@/components/payType/icon-alipay.png'),
                                type: QZ_PAY_TYPE.ALIPAY_H5
                            }, {
                                name: '微信支付',
                                icon: require('@/components/payType/icon-wechat.png'),
                                selectColor: '#49c483',
                                type: QZ_PAY_TYPE.WXPAY_H5
                            }];
                        }
                        else if (this.$store.getters.isWechatMin) {
                            // 微信小程序
                            this.payList = [{
                                name: '微信支付',
                                icon: require('@/components/payType/icon-wechat.png'),
                                selectColor: '#49c483',
                                type: QZ_PAY_TYPE.WXPAY_MIN
                            }];
                        }
                        else if (this.$store.getters.isWechatBrowser) {
                            // 微信浏览器
                            this.payList = [{
                                name: '微信支付',
                                icon: require('@/components/payType/icon-wechat.png'),
                                selectColor: '#49c483',
                                type: QZ_PAY_TYPE.WXPAY_BROWSER
                            }];
                        }
                        else if (this.$store.getters.isTTMin) {
                            // 抖音小程序
                        }
                    }
                })
            },
            isSafari() {
                var isSafari = false;
                var ua = navigator.userAgent.toLowerCase();
                //判断是不是在iPhone的Safair浏览器打开的本页面
                if (ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('safari') > -1 &&
                    ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
                    ua.indexOf('ios') === -1 && ua.indexOf('browser') === -1) {
                    isSafari = true;
                }
                return isSafari;
            },
            isEdge() {
                var isEdge = false;
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('safari') > -1 &&
                    ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
                    (ua.indexOf('edgios') > -1 || ua.indexOf('edge')) && ua.indexOf('browser') === -1) {
                    isEdge = true;
                }
                return isEdge;
            },
            getData() {
                if (this._isDestroyed) {
                    // 页面跳转即停止请求
                    return;
                }
                this.$util.get(`${GET_KFC_ORDER_INFO_OF_ORDER_NO}?orderNo=${this.orderNo}`).then(res => {
                    if (res.success) {
                        this.orderDetail = res.data;
                        this.downTime = res.data.paymentExpireSeconds;
                        window.clearInterval(this.downTimer);
                        if (res.data.paymentExpireSeconds > 0) {
                            this.setDownTimeText();
                            this.downTimer = setInterval(() => {
                                this.downTime--;
                                this.setDownTimeText();
                                if (this.downTime == 0) {
                                    window.clearInterval(this.downTimer);
                                }
                            }, 1000);
                        }
                        if (this.orderDetail.status != 0) {
                            this.otherPageJump();
                        }
                    }
                })
                setTimeout(() => {
                    this.getData();
                }, 2000);
            },
            otherPageJump() {
                if (this.jumpNum == 0) {
                    this.jumpNum++;

                    if (this.$store.getters.getExposeMethod.toOrderPay) {
                        window.location.href = `${window.location.origin}${PAGE_ORDER_INFO}?orderNo=${this.orderNo}`;
                    }
                    else {
                        window.location.href = `${window.location.protocol}${process.env.VUE_APP_API_BASE}${PLATFORM_ORDER_OVER}?orderType=${this.orderType}&orderNo=${this.orderNo}`;
                    }

                }

            },
            setDownTimeText() {
                this.downTimeText = `${this.zero(~~(this.downTime / 60))}:${this.zero(this.downTime % 60)}`;
            },
            zero(n) {
                return n < 10 ? '0' + (n) : n;
            },
            clickPay(index) {
                this.selectIndex = index;
            },
            submit() {
                console.log(this.$store.getters.getExposeMethod);
                console.log(this.payList[this.selectIndex].type == QZ_PAY_TYPE.WXPAY_MIN, this.$store.getters.getExposeMethod.expandName == 'QiShiKa');
                if (this.payList[this.selectIndex].type == QZ_PAY_TYPE.WXPAY_MIN && this.$store.getters.getExposeMethod.expandName == 'QiShiKa') {
                    require('@/lib/qsk/qsk.min-1.0.js');
                    this.$util.post(WX_PAY_JSAPI, {
                        orderType: this.orderType,
                        orderId: this.orderDetail.id,
                    }).then(payJSApiRes => {
                        if (payJSApiRes.success) {
                            let {
                                timeStamp,
                                nonceStr,
                                packageValue,
                                paySign,
                                signType,
                                appId,
                            } = payJSApiRes.data;
                            let successUrl = '';
                            let failUrl = '';
                            if (this.$store.getters.getExposeMethod.toOrderPay) {
                                successUrl = `${window.location.origin}${PAGE_ORDER_INFO}?orderNo=${this.orderNo}&backIndex=true&backIndexType=4`;
                                failUrl = `${window.location.origin}${PAGE_ORDER_INFO}?orderNo=${this.orderNo}&backIndex=true&backIndexType=4`;
                            }
                            else {
                                successUrl = `${window.location.protocol}${process.env.VUE_APP_API_BASE}${PLATFORM_ORDER_OVER}?orderType=${this.orderType}&orderNo=${this.orderNo}`;
                                failUrl = `${window.location.protocol}${process.env.VUE_APP_API_BASE}${PLATFORM_ORDER_OVER}?orderType=${this.orderType}&orderNo=${this.orderNo}`
                            }
                            window.QskMPSdk.pay({
                                successUrl: successUrl,
                                failUrl: failUrl,
                                payParams: {
                                    timeStamp,
                                    nonceStr,
                                    package: packageValue,
                                    signType,
                                    paySign,
                                },
                            }).then(() => {
                                console.log('唤醒骑士卡jssdk成功')
                            }).catch(err => {
                                console.log('唤醒骑士卡jssdk失败', err);
                            });
                        }
                    })
                }
                else {
                    this.payNext();
                }

            },
            payNext() {
                let payObject = this.payList[this.selectIndex];
                pay({
                    orderType: this.orderType,
                    orderId: this.orderDetail.id,
                    orderNo: this.orderDetail.orderNo,
                    payType: payObject.type,
                    // returnUrl: `${window.location.origin}${PAGE_ORDER_INFO}?orderId=${this.orderDetail.id}&orderType=${this.orderType}`,
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/lib/base.less');
    .page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(248, 248, 248, 1);
        text-align: center;
        .store-name {
            width: 100%;
            text-align: center;
            height: .pxToRem(80) [];
            line-height: .pxToRem(80) [];
            font-size: .pxToRem(32) [];
            font-weight: bold;
            margin-top: .pxToRem(80) [];
        }
        .store-address {
            width: 100%;
            text-align: center;
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            font-size: .pxToRem(24) [];
        }
        .price {
            width: 100%;
            text-align: center;
            height: .pxToRem(120) [];
            line-height: .pxToRem(120) [];
            font-size: .pxToRem(48) [];
            font-weight: bold;
            &::before {
                content: '¥';
                font-size: .pxToRem(32) [];
                font-weight: unset;
                margin-right: .pxToRem(4) [];
            }
        }
        .time-down {
            display: inline-block;
            border: .pxToRem(1) [] solid rgba(240, 240, 240, 1);
            margin: 0 auto;
            padding: 0 .pxToRem(20) [];
            font-size: .pxToRem(24) [];
            height: .pxToRem(36) [];
            line-height: .pxToRem(36) [];
            border-radius: .pxToRem(18) [];
        }
        .pay-list {
            position: absolute;
            left: .pxToRem(24) [];
            right: .pxToRem(24) [];
            background: white;
            border-radius: .pxToRem(8) [];
            margin-top: .pxToRem(40) [];
            .pay-item {
                height: .pxToRem(122) [];
                line-height: .pxToRem(122) [];
                border-bottom: .pxToRem(1) [] solid rgba(240, 240, 240, 1);
                .icon {
                    .base-center-y;
                    left: .pxToRem(24) [];
                    width: .pxToRem(68) [];
                    height: .pxToRem(68) [];
                }
                .name {
                    .base-center-y;
                    font-size: .pxToRem(28) [];
                    left: .pxToRem(110) [];
                }
                .select {
                    .base-center-y;
                    right: .pxToRem(24) [];
                    width: .pxToRem(48) [];
                    height: .pxToRem(48) [];
                }
                &:last-child {
                    border-bottom-width: 0;
                }
            }
        }
        .footer {
            position: absolute;
            bottom: .pxToRem(24) [];
            left: .pxToRem(24) [];
            right: .pxToRem(24) [];
            height: .pxToRem(98) [];

            .pay-btn {
                line-height: .pxToRem(98) [];
                background: linear-gradient(#4f4f4f, #252220);
                color: #f8e3c0;
                border-radius: .pxToRem(8) [];
                font-size: .pxToRem(36) [];
                font-weight: bold;
            }
        }
    }
</style>

